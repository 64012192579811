import { getInstance } from "../..";
import "./style.css";

export default class Dropdown {
  static selector = ".dropdown";

  constructor(block) {
    this.block = block;

    this.customSelect = this.block;
    this.selectedOption = this.customSelect.querySelector(
      ".dropdown-container"
    );
    this.selectOptions = this.customSelect.querySelector(".options-container");
    this.updateOptionsList();
    this.isOpen = false;
    this.selectedIndex = 0;

    this.setupEventListeners();

    // At the moment this class only handles the accessibility of the component
    // The rest is defined in the Table component
  }

  updateOptionsList = () => {
    this.options = Array.from(this.selectOptions.querySelectorAll("li"));
  };

  toggleOptions = () => {
    const options = this.block.querySelectorAll("li");

    if (options.length > 0) {
      this.isOpen = !this.isOpen;

      if (
        this.block.classList.contains("sort-by") ||
        this.block.classList.contains("sort-order")
      ) {
        // handle sorter dropdowns differently
        if (this.block.classList.contains("active")) {
          this.block.classList.remove("active");
        } else {
          this.block.classList.add("active");
        }
      } else {
        if (this.block.classList.contains("dropdown--free-tex")) {
          this.block.classList.toggle("active", this.isOpen);
        }
      }

      if (this.isOpen) {
        this.options.forEach((option, index) => {
          // Add tabindex attribute to make options focusable
          option.setAttribute("tabindex", "0");
        });
        this.selectedIndex = 0;

        if (!this.block.classList.contains("dropdown--free-text")) {
          this.selectOptions.querySelector('li[aria-selected="false"]').focus();
        }
      } else {
        this.options.forEach((option, index) => {
          // Revert tabindex attribute to make options non-focusable
          option.setAttribute("tabindex", "-1");
        });
        if (!this.block.classList.contains("dropdown--free-text")) {
          this.customSelect.focus();
        }
      }
    }
  };

  handleOptionSelection = (optionIndex) => {
    // TODO: move logic here. Currently using the method defined in the Table component
    // But ideally all the logic related to the dropdown should be moved here
    this.selectedIndex = optionIndex;
    this.tableInstance = getInstance(document.querySelector(".etb-table"));
    this.tableInstance.handleDropdownOptionClick(
      this.selectedOption,
      this.options[this.selectedIndex],
      this.block
    );
  };

  navigateOptions = (event) => {
    if (!this.isOpen) return;

    if (event.key === "ArrowUp") {
      event.preventDefault();
      const previousIndex = this.selectedIndex;
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
      this.updateAriaSelected(previousIndex, this.selectedIndex);

      this.options[this.selectedIndex].focus();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      const previousIndex = this.selectedIndex;
      if (this.selectedIndex < this.options.length - 1) {
        this.selectedIndex++;
        this.updateAriaSelected(previousIndex, this.selectedIndex);
        this.options[this.selectedIndex].focus();
      }
    } else if (event.key === "Escape") {
      event.preventDefault();
      this.customSelect.focus();
      this.block.classList.remove("active");
    } else if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.handleOptionSelection(this.selectedIndex);
    }
  };

  updateAriaSelected(previousIndex, currentIndex) {
    if (this.options[previousIndex]) {
      this.options[previousIndex].setAttribute("aria-selected", "false");
    }
    if (this.options[currentIndex]) {
      this.options[currentIndex].setAttribute("aria-selected", "true");
    }
  }

  setupEventListeners = () => {
    this.customSelect.addEventListener("click", () => this.toggleOptions());
    this.customSelect.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!this.block.classList.contains("active")) {
          this.toggleOptions();
        }
      } else if (event.key === "Escape") {
        event.preventDefault();
        this.block.classList.remove("active");
        this.block.focus();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        if (!this.block.classList.contains("active")) {
          this.toggleOptions();
        }
        const currentIndex = this.options.indexOf(document.activeElement);
        const nextIndex = (currentIndex + 1) % this.options.length;

        this.options[nextIndex].focus();
        this.updateAriaSelected(currentIndex, nextIndex);
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        if (!this.block.classList.contains("active")) {
          this.toggleOptions();
        }
        const currentIndex = this.options.indexOf(document.activeElement);
        const previousIndex =
          (currentIndex - 1 + this.options.length) % this.options.length;
        this.options[previousIndex].focus();
        this.updateAriaSelected(currentIndex, previousIndex);
      }
    });

    this.selectOptions.addEventListener("keydown", (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        this.block.classList.remove("active"); // Close dropdown
        this.optionSelected = true; // Set the flag to indicate an option was selected
      }
    });

    this.options.forEach((option, index) => {
      option.addEventListener("keydown", (event) => {
        if (event.key === "Enter" || event.key === " ") {
          event.preventDefault();
          this.handleOptionSelection(index);
          this.block.classList.remove("active"); // Close dropdown
          this.optionSelected = true; // Set the flag to indicate an option was selected
        }
      });
    });
  };

  onReady() {}

  onResize() {}

  onDestroy() {}
}
