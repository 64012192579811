import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

export default class Firebase {
  constructor() {
    this.firebaseConfig = {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      projectId: process.env.FIREBASE_PROJECT_ID,
      appId: process.env.FIREBASE_APP_ID,
      databaseURL: process.env.FIREBASE_MEASUREMENT_ID,
    };

    this.app = initializeApp(this.firebaseConfig);
    this.analytics = getAnalytics(this.app);
  }
}
