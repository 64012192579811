import "./style.css";
import { clamp } from "@utils/math.js";
import { easeInOutExpo } from "@utils/easing.js";
import { getRaf } from "@app";
import AnimatedText from "@components/AnimatedText";
import layout from "./layout.html";
import { getInstance } from "../..";

export default class Loader {
  static selector = ".loader";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.logoNode = this.block.querySelector(".loader__logo");
    this.titleNode = this.block.querySelector(".loader__title");
    this.textNode = this.block.querySelector(".loader__text");
    this.imagesToLoad = document.querySelectorAll("img[src]:not([src=''])");
    this.resourcesToLoad = this.imagesToLoad.length;
    this.resourcesLoaded = 0;
    this.reasourcesProgress0 = 0;
    this.reasourcesProgress = 0;

    this.animation = {
      duration: 800,
      ease: easeInOutExpo,
      fromValue: 0,
      toValue: 100,
      current: 0,
      setValue: (progress) => {
        this.animation.current = Math.round(this.animation.toValue * progress);
      },
    };

    document.body.addEventListener("transitionend", this.onBodyTransitionEnd);
  }

  onBodyTransitionEnd = (e) => {
    if (
      e.propertyName === "opacity" &&
      e.srcElement.classList.contains("loaded")
    ) {
      document.documentElement.classList.add("loaded");
    }
  };

  loadFontFaces = async () => {
    return new Promise(async (resolve) => {
      await document.fonts.ready;

      this.animatedLogo = new AnimatedText(this.logoNode);
      this.animatedLogo.onReady();
      this.animatedLogo.show();

      this.animatedTitle = new AnimatedText(this.titleNode);
      this.titleNodeContent = this.titleNode.querySelector(
        ".animated-text__row"
      );
      await this.animatedTitle.onReady();
      await this.animatedTitle.show();

      this.animatedText = new AnimatedText(this.textNode);
      this.textNodeContent = this.textNode.querySelector(".animated-text__row");
      this.textNodeContent.textContent = "0%";
      await this.animatedText.onReady();
      await this.animatedText.show();

      resolve();
    });
  };

  loadImages = () => {
    return new Promise(async (resolve) => {
      this.imagesToLoad.forEach((imageToLoad) => {
        let image = new Image();
        const onImageLoaded = () => {
          this.resourcesLoaded += 1;

          this.reasourcesProgress = this.resourcesLoaded / this.resourcesToLoad;
        };

        image.addEventListener("load", onImageLoaded);
        image.src = imageToLoad.src;
      });

      this.reasourcesProgress = 1;

      resolve();
    });
  };

  load = () => {
    return new Promise(async (resolve) => {
      let transitionPromise = {};
      transitionPromise.promise = new Promise(
        (resolve) => (transitionPromise.resolve = resolve)
      );
      this.raf = getRaf();
      this.raf.register("loader", (timestamp) => {
        this.render(timestamp, transitionPromise);
      });

      const imagesLoaded = this.loadImages();

      Promise.all([imagesLoaded, transitionPromise.promise]).then(() => {
        resolve();
      });
    });
  };

  hide = () => {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        this.animatedLogo.hideToTop();
        this.animatedText.hideToTop();
        this.animatedTitle.hideToTop();
        this.block.classList.add("loader--hidden");
        await this.handleTransitionEnd();

        resolve();
      }, 256);
    });
  };

  handleTransitionEnd = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        document.body.classList.add("loaded");
        this.block.style.pointerEvents = "none";

        resolve();
      }, 1600);
    });
  };

  animate = () => {
    this.textNodeContent.textContent = `${this.animation.current}%`;
  };

  render = async (timestamp, promise) => {
    if (!this.animation.startTimestamp) {
      this.animation.startTimestamp = timestamp;
    }

    const timeProgress = this.animation.ease(
      clamp(
        (timestamp - this.animation.startTimestamp) / this.animation.duration,
        0,
        1
      )
    );

    this.reasourcesProgress0 =
      this.reasourcesProgress0 +
      (this.reasourcesProgress - this.reasourcesProgress0) * 0.09;
    this.progress = Math.min(timeProgress, this.reasourcesProgress0);
    this.animation.setValue(this.progress);

    this.animate();

    if (this.animation.current === 100) {
      this.raf.unregister("loader");

      promise.resolve();
    }
  };
}
