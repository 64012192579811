import "./style.css";
import layout from "./layout.html";
import Storage from "@utils/Storage";
import { ETB_RESULTS_KEY, ETB_SETTINGS_KEY } from "../../utils/constants";
import { divideArrayByProperty, reorderObject } from "../../utils/utils";

export default class Results {
  static selector = ".etb-results";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.sectionTemplate = this.block.querySelector(
      ".results-section-template"
    );

    this.characterTemplate = this.block.querySelector(".character-template");
    this.resultsContainer = this.block.querySelector(".results-container");
    this.resultsContent = this.block.querySelector(".results-content");
  }

  showResults = () => {
    this.block.classList.remove("no-display");
  };

  hideResults = () => {
    this.block.classList.add("no-display");
  };

  updateUI = () => {
    if (!!Object.keys(this.charatersCount).length) {
      this.showResults();

      while (this.resultsContainer.childNodes.length > 1) {
        this.resultsContainer.removeChild(this.resultsContainer.lastChild);
      }

      // don't show empty styles - styles with no phrase associated with it
      for (const key in this.charatersCount) {
        if (Object.keys(this.charatersCount[key]).length === 0) {
          delete this.charatersCount[key];
        }
      }

      for (const styleKey in this.charatersCount) {
        if (this.charatersCount.hasOwnProperty(styleKey)) {
          const sectionClone =
            this.sectionTemplate.content.firstElementChild.cloneNode(true);
          const sectionStyle = sectionClone.querySelector(".beads-style");
          const sectionCount = sectionClone.querySelector(".beads-count");

          sectionStyle.innerText = styleKey;

          // extract object keys
          const sortedKeys = Object.keys(this.charatersCount[styleKey]).sort(
            (a, b) => a.localeCompare(b)
          );

          // create a new object with sorted keys
          const sortedObject = {};
          sortedKeys.forEach((sortedKey) => {
            sortedObject[sortedKey] = this.charatersCount[styleKey][sortedKey];
          });

          Object.entries(sortedObject).forEach(([char, count]) => {
            const clone =
              this.characterTemplate.content.firstElementChild.cloneNode(true);
            const charNode = clone.querySelector(".character .char");
            charNode.innerText = `${char}`;
            const countNode = clone.querySelector(".character .count");
            countNode.innerText = count;
            sectionCount.appendChild(clone);
          });

          this.resultsContainer.appendChild(sectionClone);
        }
      }
      window.App.theHeader.showHeaderButtons(
        window.App.theHeader.resultsButton
      );
    } else {
      this.hideResults();
      window.App.theHeader.hideHeaderButtons(
        window.App.theHeader.resultsButton
      );
    }
  };

  loadCharactersCount = async () => {
    this.charatersCount = await Storage.get(ETB_RESULTS_KEY);

    if (this.charatersCount) {
      this.showResults();
    } else {
      this.hideResults();
    }
    if (process.env.ENVIRONMENT === "development") {
      console.log("Loaded count results: ", this.charatersCount);
    }
    this.updateUI();
  };

  multiplyCharacters = (word, multiplier) => {
    const multipliedCharacters = [];
    for (const char of word) {
      multipliedCharacters.push(char.repeat(multiplier));
    }
    return multipliedCharacters.join("");
  };

  normalizeAndCount = (filteredData) => {
    const normalizedData = filteredData.map((d) => {
      const { phrase, quantity } = d;
      const result = this.multiplyCharacters(phrase, quantity);
      return result;
    });
    const combinedString = normalizedData
      .toString()
      .toLowerCase()
      .replace(/,/g, "")
      .replace(/'/g, "")
      .replace(/’/g, "")
      .replace(/ /g, "");

    const count = [...combinedString].reduce((acc, chr) => {
      acc[chr] = (acc[chr] || 0) + 1;
      return acc;
    }, {});

    return count;
  };

  getTotal = (filteredData) => {
    // get total without beads style differentiation
    const total = this.normalizeAndCount(filteredData);
    return total;
  };

  saveCharactersCount = async (data) => {
    const filteredData = data.filter((d) => d.completed === false);
    const dataByBeadsStyle = divideArrayByProperty(filteredData, "beadsStyle");

    let processedData = {};

    for (const styleKey in dataByBeadsStyle) {
      if (dataByBeadsStyle.hasOwnProperty(styleKey)) {
        const count = this.normalizeAndCount(dataByBeadsStyle[styleKey]);

        processedData[styleKey] = count;
      }
    }

    let total;

    if (filteredData.length > 0) {
      total = this.getTotal(filteredData);
      processedData = { ...processedData, total };
    }

    // get the last key in the object
    const keysLength = Object.keys(processedData).length;

    let reorderedObject = reorderObject(processedData, "total", keysLength - 1);

    if ("any style" in reorderedObject) {
      reorderedObject = reorderObject(
        reorderedObject,
        "any style",
        keysLength - 2
      );
    }

    // // save to storage
    if (process.env.ENVIRONMENT === "development") {
      console.log("Saving count: ", reorderedObject);
    }
    await Storage.set(ETB_RESULTS_KEY, reorderedObject);
    this.charatersCount = reorderedObject;
    await this.updateUI();
  };

  onReady() {
    this.mounted = true;
  }

  onComplete = () => {
    this.completed = true;
  };

  onResize() {}

  onDestroy() {}
}
