import inViewport from "in-viewport";

import "./style.css";
import { getObserver } from "../..";

// Show elements with animation when these get in view for the first time
// Component name is kept short for ease of use.

// Default animation is `fadeIn`
// - x--slide-up: Fade in and slide up

const DEFAULT_STAGGER_TIME = 200;
const staggeredGroups = [];

export default class X {
  static name = "X";
  static selector = ".x";

  constructor(block) {
    this.block = block;

    this.inViewportWatcher = null;
    this.staggerTimeout = null;

    // search for staggered group
    const staggeredGroup = this.block.closest(".x-staggered-group");
    if (staggeredGroup) {
      // find or init staggered group
      const staggeredGroupIndex = staggeredGroups.findIndex(
        ({ element }) => element === staggeredGroup
      );
      if (staggeredGroupIndex !== -1) {
        // group already exists
        this.staggeredGroup = staggeredGroups[staggeredGroupIndex];
        this.staggeredGroup.instances.push(this);
        this.staggerIndex = this.staggeredGroup.instances.length - 1;
      } else {
        // create new group
        this.staggeredGroup = {
          element: staggeredGroup,
          instances: [this],
          lastAnimationStartTime: 0,
          staggerTime: staggeredGroup.dataset.staggerTime
            ? parseInt(staggeredGroup.dataset.staggerTime, 10)
            : DEFAULT_STAGGER_TIME,
          enteredItems: [],
          waitList: [],
        };
        this.staggerIndex = 0;
        staggeredGroups.push(this.staggeredGroup);
      }
    }
  }

  enter = () => {
    if (this.staggeredGroup) {
      const {
        lastAnimationStartTime,
        staggerTime,
        enteredItems,
        waitList,
        instances,
      } = this.staggeredGroup;

      if (
        this.staggerIndex > 0 &&
        (enteredItems.length < this.staggerIndex ||
          enteredItems[this.staggerIndex - 1] !== this.staggerIndex - 1)
      ) {
        // some previous indexes are still needed to enter
        waitList.push(this.staggerIndex);
        waitList.sort();
      } else {
        // this index is ready to enter
        const now = Date.now();

        if (now - lastAnimationStartTime > staggerTime) {
          this.staggeredGroup.lastAnimationStartTime = now;
          this.block.classList.add("x--in-view");
          enteredItems.push(this.staggerIndex);
          enteredItems.sort();

          // get item from waitList
          if (waitList.length > 0) {
            const waitIndex = waitList.shift();
            const waitItem = instances.find(
              (instance) => instance.staggerIndex === waitIndex
            );
            waitItem.enter();
          }
        } else {
          const wait = staggerTime - (now - lastAnimationStartTime);
          this.staggerTimeout = setTimeout(this.enter, wait);
        }
      }
    } else {
      this.block.classList.add("x--in-view");
    }
  };

  // Event handlers
  handleInViewport = () => {
    this.enter();
  };

  // Lifecycle methods
  onReady = async () => {
    this.mounted = true;
  };

  onComplete = async () => {
    this.completed = true;
    this.inViewportWatcher = inViewport(this.block, this.handleInViewport);
    // this.block.dataset.intersectionRatio = 0.8;
    // const observer = getObserver();
    // observer.register(this.block.dataset.instanceIndex, this.enter, this.block);
  };

  onDestroy = () => {
    if (this.inViewportWatcher) {
      this.inViewportWatcher.dispose();
    }

    if (this.staggerTimeout) {
      clearTimeout(this.staggerTimeout);
    }
  };
}
