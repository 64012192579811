import throttle from "lodash/throttle";
import { tablet, laptop } from "./config/breakpoints.js";

import "@styles/fonts.css";
import "@styles/base.css";
import "@styles/background.css";
import "@styles/colors.css";
import "@styles/variables.css";
import "@styles/transitions.css";
import "@styles/normalize.css";

import "@styles/spinner.css";

import BackupModal from "@blocks/BackupModal";
import Footer from "@blocks/Footer";
import Results from "@blocks/Results";
import Stats from "@blocks/Stats";
import Table from "@blocks/Table";

import "@components/ButtonTooltip";
import Columns from "@components/Columns";
import CookieBanner from "@components/CookieBanner";
import "@components/Counter";
import "@components/Checkbox";
import Dropdown from "@components/Dropdown";
import Header from "@components/Header";
import Loader from "@components/Loader";
import "@components/SectionTitle";
import ScrollToTop from "@components/ScrollToTop";
import X from "@components/X";

import Firebase from "@utils/Firebase";
import Raf from "@utils/Raf";
import Observer from "@utils/Observer";
// import Scroll from "@utils/Scroll";
import vh from "@utils/vh";

const MODULES = [
  BackupModal,
  Columns,
  Dropdown,
  Footer,
  Results,
  Stats,
  ScrollToTop,
  Table,
  X,
];

const MAIN_MODULES = { theHeader: Header, theCookieBanner: CookieBanner };

const MODALS = [];

const SERVICES = [];

export const getObserver = () => {
  return window.App.observer;
};

export const getScroll = () => {
  return window.App.scroll;
};

export const getInstances = (el) => {
  const instanceIndex = el.dataset.instanceIndex;

  if (!instanceIndex) {
    return [];
  }

  return instanceIndex.split(",").map((i) => {
    return window.App.instances[parseInt(i, 10)];
  });
};

export const getInstance = (el) => {
  const instances = getInstances(el);

  if (!instances.length) {
    return null;
  }

  return instances[0];
};

export const getRaf = () => {
  return window.App.raf;
};

export const getPageTransitioner = () => {
  return window.App.thePageTransitioner;
};

export const getDestroyArea = () => {
  return window.App.destroyArea;
};

export const getInitArea = () => {
  return window.App.initArea;
};

export const getOnPageChangeComplete = () => {
  return window.App.onPageChangeComplete;
};

export const getRouter = () => {
  return window.App.router;
};

class App {
  lastIndex = 0;

  constructor() {
    window.addEventListener("DOMContentLoaded", this.initApp);
    window.addEventListener("resize", throttle(this.onResize, 150));
    window.addEventListener("scroll", throttle(this.onScroll, 150));
  }

  initApp = async () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register(`sw.js`).then(
        function (registration) {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        function (err) {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    }

    this.firebaseService = new Firebase();
    this.loader = new Loader(document.querySelector(".loader"));
    this.observer = new Observer();
    this.raf = new Raf();
    this.lastIndex = 0;
    this.currentView = this.getCurrentView();
    this.instances = [];
    this.modals = {};

    document.documentElement.style.setProperty("--vh", `${vh()}px`);

    const main = document.querySelector("#main");
    if (main) {
      history.replaceState(
        {
          title: document.title,
          main: main.innerHTML,
          bodyClassAttribute: document.body.getAttribute("class"),
        },
        document.title,
        window.location.href
      );
    }

    this.beautifyUrl();

    // this.scroll = new Scroll();

    await this.loader?.loadFontFaces();

    const resourcesLoaded = this.loader?.load();
    const areaInited = this.initArea(document);
    await Promise.all([resourcesLoaded, areaInited]);

    // this.router = new Router();

    await this.initClones();

    await this.loader?.hide();

    window.App.theCookieBanner?.open();
  };

  initArea = (parentElement) => {
    return new Promise(async (resolve, reject) => {
      if (!parentElement) {
        return [];
      }

      // Create module instances
      const newInstances = MODULES.filter((module) =>
        module.hasOwnProperty("selector")
      ).flatMap((Module) => {
        const blocks = [...parentElement.querySelectorAll(Module.selector)];
        return blocks
          .filter((block) => !block.dataset["instanceIndex" + Module.name])
          .map((block) => {
            const previousIndexes = block.dataset.instanceIndex;
            block.dataset.instanceIndex = previousIndexes
              ? [previousIndexes, this.lastIndex].join(",")
              : this.lastIndex;
            block.dataset["instanceIndex" + Module.name] = this.lastIndex;

            this.lastIndex++;

            return new Module(block);
          });
      });

      // Create modals instances
      Object.keys(MODALS).forEach((k) => {
        const Modal = MODALS[k];

        if (!Modal.hasOwnProperty("selector")) {
          return;
        }

        const block = parentElement.querySelector(Modal.selector);
        if (!block) {
          return;
        }

        if (block.dataset["instanceIndex" + Modal.name]) {
          return;
        }

        const previousIndexes = block.dataset.instanceIndex;
        block.dataset.instanceIndex = previousIndexes
          ? [previousIndexes, this.lastIndex].join(",")
          : this.lastIndex;
        block.dataset["instanceIndex" + Modal.name] = this.lastIndex;
        this.lastIndex++;

        this.modals[k] = new Modal(block);

        newInstances.push(this.modals[k]);
      });

      // Create main module instances
      Object.keys(MAIN_MODULES).forEach((k) => {
        const Module = MAIN_MODULES[k];

        if (!Module.hasOwnProperty("selector")) {
          return;
        }

        const block = parentElement.querySelector(Module.selector);

        if (!block) {
          return;
        }

        if (block.dataset["instanceIndex" + Module.name]) {
          return;
        }

        const previousIndexes = block.dataset.instanceIndex;
        block.dataset.instanceIndex = previousIndexes
          ? [previousIndexes, this.lastIndex].join(",")
          : this.lastIndex;
        block.dataset["instanceIndex" + Module.name] = this.lastIndex;
        this.lastIndex++;

        this[k] = new Module(block);

        newInstances.push(this[k]);
      });

      // Create services instances
      Object.keys(SERVICES).forEach((k) => {
        if (this[k]) {
          // Services can only be initialised once and cannot be destroyed
          return;
        }

        const Module = SERVICES[k];

        this.lastIndex++;

        this[k] = new Module();

        newInstances.push(this[k]);
      });

      // Call onReady method if any
      await Promise.all(
        newInstances.map(async (instance) => {
          if (instance.onReady) {
            await instance.onReady();
          }
        })
      );

      this.instances = this.instances.concat(newInstances);

      resolve(newInstances);
    });
  };

  initClones = async () => {
    return new Promise(async (resolve, reject) => {
      this.instances = this.instances.concat(
        MODULES.flatMap((module) => {
          if (!module.hasOwnProperty("selector")) {
            return;
          }

          const blocks = [...document.querySelectorAll(module.selector)];
          return blocks
            .map((block) => {
              const blockInstanceIndex = block.getAttribute(
                "data-instance-index"
              );
              if (blockInstanceIndex && blockInstanceIndex !== "") {
                return null;
              }

              const previousIndexes = block.dataset.instanceIndex;
              block.dataset.instanceIndex = previousIndexes
                ? [previousIndexes, this.lastIndex].join(",")
                : this.lastIndex;
              block.dataset["instanceIndex" + module.name] = this.lastIndex;

              this.lastIndex++;

              return new module(block);
            })
            .filter((v) => !!v);
        })
      );

      await Promise.all(
        this.instances.map(async (instance) => {
          if (instance.onReady && !instance.mounted) {
            await instance.onReady();
          }
        })
      );

      await Promise.all(
        this.instances.map(async (instance) => {
          if (instance.onComplete && !instance.completed) {
            await instance.onComplete();
          }
        })
      );

      document.body.classList.add("loaded");

      resolve();
    });
  };

  destroyArea = (parentElement, keep = []) => {
    [...parentElement.children].forEach((element) => {
      // Skip destroy if element is in `keep`
      if (keep.some((s) => element.matches && element.matches(s))) {
        return;
      }

      // If this element has an instance attached
      if (element.dataset.instanceIndex) {
        // Get instance
        const instances = getInstances(element);

        instances.forEach((instance) => {
          // Call onDestroy method if any
          if (instance.onDestroy) {
            instance.onDestroy();
          }

          // Remove instance references
          element.dataset.instanceIndex.split(",").map((i) => {
            this.instances[parseInt(i, 10)] = null;
          });
          Object.keys(MAIN_MODULES).forEach((k) => {
            if (this[k] === instance) {
              this[k] = null;
            }
          });
          Object.keys(MODALS).forEach((k) => {
            if (this.modals[k] === instance) {
              this.modals[k] = null;
            }
          });
        });
      }

      element.remove();
    });
  };

  beautifyUrl = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    // check if there's a path or parameters
    if (url.pathname !== "/" || url.search !== "") {
      // replace the path and parameters with just the main URL
      url.pathname = "/";
      url.search = "";
      const modifiedUrlString = url.toString();
      window.history.replaceState({}, document.title, modifiedUrlString);
    }
  };

  onResize = (e) => {
    if (!this.instances) {
      return;
    }

    const currentView = this.getCurrentView();
    const changedView = this.currentView !== currentView;

    this.currentView = currentView;

    document.documentElement.style.setProperty("--vh", `${vh()}px`);

    this.instances
      .filter((i) => !!i)
      .forEach((instance) => {
        if (instance.onResize) {
          instance.onResize(e, {
            changedView,
          });
        }
      });
  };

  getCurrentView() {
    const isTablet = window.innerWidth >= tablet;
    const isLaptop = window.innerWidth >= laptop;

    if (isLaptop) {
      return "laptop";
    }

    if (isTablet) {
      return "tablet";
    }

    return "mobile";
  }

  onScroll = (e) => {
    if (!this.instances) {
      return;
    }

    this.instances
      .filter((i) => !!i)
      .forEach((instance) => {
        if (instance.onScroll) {
          instance.onScroll(e);
        }
      });
  };

  onPageChangeComplete = () => {
    return new Promise(async (resolve, reject) => {
      await Promise.all(
        this.instances.map(async (instance) => {
          if (instance && instance.onPageChangeComplete) {
            await instance.onPageChangeComplete();
          }
        })
      );

      resolve();
    });
  };
}

window.App = new App();
