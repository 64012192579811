import "./style.css";
import layout from "./layout.html";
import { restoreBackup } from "../../utils/backup";
import { exportBackup } from "../../utils/backup";

export default class BackupModal {
  static selector = ".etb-backup-modal";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.fileInput = this.block.querySelector(".file-input");
    this.exportButton = this.block.querySelector(".export-button");
    this.uploadButton = this.block.querySelector(".import-button");

    this.modal = this.block.querySelector(".modal");
    this.modalContent = this.block.querySelector(".modal-content");
    this.closeModalButton = this.block.querySelector(".close-modal-button");

    this.uploadError = this.block.querySelector(".modal-error");

    this.isImportLoading = false;
    this.isExportLoading = false;
  }
  enableFocusTrap = () => {
    // Disable tabbing to elements outside the modal
    const outsideElements = document.querySelectorAll(
      'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    outsideElements.forEach((element) => {
      element.setAttribute("tabindex", "-1");
    });

    // Enable tabbing to elements within the modal
    const focusableElements = this.modal.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    focusableElements.forEach((element) => {
      element.removeAttribute("tabindex");
    });

    // Add a listener to trap focus within the modal
    this.modal.addEventListener("keydown", this.trapFocus);
  };

  disableFocusTrap = () => {
    // Re-enable tabbing to elements outside the modal
    const outsideElements = document.querySelectorAll(
      'a, button, input, select, textarea, [tabindex="-1"]'
    );
    outsideElements.forEach((element) => {
      element.setAttribute("tabindex", "0");
    });

    // Remove the listener for focus trapping
    this.modal.removeEventListener("keydown", this.trapFocus);
  };

  trapFocus = (e) => {
    if (e.key === "Tab") {
      const focusableElements = this.modal.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusable = focusableElements[0];
      const lastFocusable = focusableElements[focusableElements.length - 1];

      if (e.shiftKey) {
        // If Shift + Tab is pressed and the first focusable element is focused, focus the last one.
        if (document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable.focus();
        }
      } else {
        // If Tab is pressed and the last focusable element is focused, focus the first one.
        if (document.activeElement === lastFocusable) {
          e.preventDefault();
          firstFocusable.focus();
        }
      }
    }
  };

  openModal = () => {
    this.modal.classList.add("open");
    document.body.classList.add("backup-modal-open");
    document.body.classList.add("no-scroll");
    this.enableFocusTrap();
    this.uploadButton.focus();
  };

  closeModal = () => {
    this.modal.classList.remove("open");
    document.body.classList.remove("backup-modal-open");
    document.body.classList.remove("no-scroll");
    this.disableFocusTrap();
  };

  refreshPage = () => {
    window.location.reload();
  };

  handleButtonsState = (button, loading) => {
    if (loading) {
      button.querySelector(".spinner").classList.add("show");
      button.disabled = true;
      button.classList.add("hide-label");
    } else {
      button.querySelector(".spinner").classList.remove("show");
      button.disabled = false;
      button.classList.remove("hide-label");
    }
  };

  handleFileUpload = async (event) => {
    try {
      this.uploadError.classList.remove("show");
      this.isImportLoading = true;
      this.handleButtonsState(this.uploadButton, this.isImportLoading);
      await restoreBackup(event);
      setTimeout(() => {
        this.isImportLoading = false;
        this.handleButtonsState(this.uploadButton, this.isImportLoading);
        this.closeModal();
        this.refreshPage();
      }, 500);
    } catch (error) {
      this.isImportLoading = false;
      this.handleButtonsState(this.uploadButton, this.isImportLoading);
      console.log("Error while uploading backup: ", error);
      this.buildErrorMessage("restoring");
      this.uploadError.classList.add("show");
    }
  };

  onUploadButtonClick = () => {
    fileInput.click();
  };

  onExportClick = async () => {
    try {
      this.uploadError.classList.remove("show");
      this.isExportLoading = true;
      this.handleButtonsState(this.exportButton, this.isExportLoading);
      await exportBackup();
    } catch (error) {
      console.log("Error while exporting backup: ", error);
      this.buildErrorMessage("exporting");
      this.uploadError.classList.add("show");
    }

    this.isExportLoading = false;
    this.handleButtonsState(this.exportButton, this.isExportLoading);
  };

  buildErrorMessage = (action) => {
    this.uploadError.innerText = `There was an error while ${action} your backup. Please refresh the page
    and try again.`;
  };

  onDocumentClick = (e) => {
    if (e.target.matches(".modal")) {
      this.closeModal();
    }
  };

  onReady() {
    this.mounted = true;

    this.uploadButton.addEventListener("click", this.onUploadButtonClick);
    this.exportButton.addEventListener("click", this.onExportClick);
    this.fileInput.addEventListener("change", this.handleFileUpload);
    this.closeModalButton.addEventListener("click", this.closeModal);

    this.block.addEventListener("click", this.onDocumentClick);
    // this.handleButtonsState();
  }

  onResize() {}

  onDestroy() {
    this.uploadButton.removeEventListener("click", this.onUploadButtonClick);
    this.exportButton.removeEventListener("click", this.onExportClick);
    this.fileInput.removeEventListener("change", this.handleFileUpload);
    this.closeModalButton.removeEventListener("click", this.closeModal);
    this.block.removeEventListener("click", this.onDocumentClick);
  }
}
