import "./style.css";
import layout from "./layout.html";
import Storage from "@utils/Storage";
import { ETB_COOKIE_BANNER } from "../../utils/constants";

export default class CookieBanner {
  static selector = ".etb-cookies";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.dismissButton = this.block.querySelector(".banner-close-button");
    this.acceptButton = this.block.querySelector(".banner-accept-button");
  }

  open = () => {
    if (!this.cookie || this.cookie !== true) {
      this.block.classList.add("show");
    }
  };

  onAcceptClick = async () => {
    await Storage.set(ETB_COOKIE_BANNER, "true");
    this.block.classList.remove("show");
  };

  onReady() {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      this.cookie = await Storage.get(ETB_COOKIE_BANNER);

      if (!this.cookie || this.cookie !== true) {
        this.dismissButton.addEventListener("click", this.onAcceptClick);
        this.acceptButton.addEventListener("click", this.onAcceptClick);
      }

      resolve();
    });
  }

  onResize() {}

  onDestroy() {}
}
