import "./style.css";
import layout from "./layout.html";
import tsData from "@utils/ts-data";
import { getInstance } from "../../index";
import Storage from "@utils/Storage";
import { ETB_DATA_KEY, ETB_SETTINGS_KEY } from "../../utils/constants";
import { divideArrayByProperty } from "../../utils/utils";
import Fuse from "fuse.js";
export default class Table {
  static selector = ".etb-table";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.phrases = ["Fearless", "Speak now!", "R&d"];

    this.rowTemplate = this.block.querySelector(".row-template");
    this.tableBody = this.block.querySelector(".rows-content");
    this.addRowButton = this.block.querySelector(".add-row-button");
    this.rowsContainer = this.block.querySelector(".rows-container");
    this.totalBraceletsElement = this.block.querySelector(
      ".table-total-bracelets span"
    );

    this.completedCheckbox = this.block.querySelector(".completed-checkbox");

    this.sorter = this.block.querySelector(".sorter");
    this.sortByElement = this.block.querySelector(".sort-by");
    this.sortBySelected = this.sortByElement.querySelector(".sort-by-selected");
    this.sortOrderElement = this.block.querySelector(".sort-order");
    this.sortOrderSelected = this.sortOrderElement.querySelector(
      ".sort-order-selected"
    );
    this.sorterSpinner = this.sorter.querySelector(".spinner");
    this.sorterLoading = false;

    this.lastRowId = 0;

    this.typingTimer;
    this.typingDelay = 1000;

    this.fuseOptions = {
      includeScore: true,
      threshold: 0.4,
    };

    this.fuse = new Fuse([], this.fuseOptions);
  }

  initTable = async (sortBy, sortOrder) => {
    // clear table
    while (this.rowsContainer.childNodes.length > 0) {
      this.rowsContainer.removeChild(this.rowsContainer.lastChild);
    }

    this.storedData = await Storage.get(ETB_DATA_KEY);

    if (!!this.storedData?.length) {
      this.getTotalBraceletsToMake(this.storedData);
      this.resultsInstance?.loadCharactersCount();

      const data = this.storedData;

      await this.sortData(
        data,
        sortBy ?? this.sortBy,
        sortOrder ?? this.sortOrder
      );

      if (process.env.ENVIRONMENT === "development") {
        console.log("Stored data loaded and sorted: ", data);
      }

      data.forEach((d, index) => {
        this.addNewRow(d, index);
      });
    } else {
      this.addNewRow();
    }
  };

  getTotalBraceletsToMake = (data) => {
    const filteredData = data.filter((item) => !item.completed);

    // calculate the total count based on the "quantity" multiplier
    const totalCount = filteredData.reduce((count, item) => {
      return count + parseInt(item.quantity, 10);
    }, 0);

    this.totalBraceletsElement.innerText = totalCount;
  };

  sortData = async (data, sortBy = "album", order = "ascending") => {
    sortBy = sortBy.toLowerCase();
    order = order.toLowerCase();

    if (sortBy === "album") {
      const { customAlbumsOrder } = tsData;

      // sort by custom album order
      if (order.toLowerCase() === "ascending") {
        data.sort((a, b) => {
          const albumOrderA = customAlbumsOrder[a.album] || Infinity;
          const albumOrderB = customAlbumsOrder[b.album] || Infinity;

          // if (albumOrderA === albumOrderB) {
          //   // If the custom order values are equal, sort by "phrase" alphabetically
          //   return a.phrase.localeCompare(b.phrase);
          // }

          return albumOrderA - albumOrderB;
        });
      } else {
        data.sort((a, b) => {
          const albumOrderA = customAlbumsOrder[a.album] || -Infinity;
          const albumOrderB = customAlbumsOrder[b.album] || -Infinity;

          // if (albumOrderA === albumOrderB) {
          //   // If the custom order values are equal, sort by "phrase" alphabetically
          //   return a.phrase.localeCompare(b.phrase);
          // }
          return albumOrderB - albumOrderA;
        });
      }
    } else {
      // sort by phrase alphabetically
      if (order.toLowerCase() === "ascending") {
        data.sort((a, b) => a.phrase.localeCompare(b.phrase));
      } else {
        data.sort((a, b) => b.phrase.localeCompare(a.phrase));
      }
    }
  };

  onAddClick = () => {
    const isAddButton = true;
    this.addNewRow(null, null, isAddButton);
  };

  addNewRow = async (data = null, index, isAddButton = false) => {
    const clone = this.rowTemplate.content.firstElementChild.cloneNode(true);
    const cloneId = data ? index + 1 : this.lastRowId + 1;
    const isCompleted = !data ? false : data.completed;
    clone.setAttribute("data-id", cloneId);
    clone.setAttribute("data-completed", isCompleted);
    this.rowsContainer.appendChild(clone);

    this.initPhraseInputField(clone, cloneId, isAddButton);
    this.initBeadsStyleInputField(clone, cloneId);
    this.initDropdown(clone);
    this.initQuantityButtons(clone);
    this.initArchiveButton(clone);

    // check if this is the very first row
    const rows = this.block.querySelectorAll(".row");
    if (rows.length === 1) {
      const isFirstAndOnly = true;
      this.initRemoveButton(clone, isFirstAndOnly);
    } else {
      rows.forEach((row) => this.initRemoveButton(row));
      this.initRemoveButton(clone);
    }

    // update last row id with the id of the last created clone
    this.lastRowId = cloneId;

    // handle completed rows
    const checkbox = this.block.querySelector(".checkbox");

    rows.forEach((row) => {
      const isCompleted = row.getAttribute("data-completed");

      if (checkbox.checked && isCompleted === "true") {
        row.classList.remove("no-display");
      } else if (!checkbox.checked && isCompleted === "true") {
        row.classList.add("no-display");
      }
    });

    if (data) {
      this.fillPhraseInputField(clone, data);
      this.fillBeadsStyleInputField(clone, data);
      this.fillDropdown(clone, data);
      this.fillQuantityButtons(clone, data);
    }

    this.addRowButton.classList.remove("no-display");
    this.addRowButton.classList.add("flex");

    window.App.initClones();
  };

  resetIds = () => {
    const rows = this.block.querySelectorAll(".row");
    rows.forEach((row, index) => {
      const id = index + 1;
      row.setAttribute("data-id", id);
      this.setLabelId("phrase-input", "phrase label", row, id);
      this.setLabelId("beads-style-input", "beads-style label", row, id);
    });
  };

  removeRow = (e, rowId, deleteData = false) => {
    e.target.closest("div.row").remove();

    this.resetIds();

    // check if only one is remaining
    const rows = this.block.querySelectorAll(".row");
    if (rows.length === 1) {
      const removeButton = this.block.querySelector(
        `[data-id="1"] .remove-row-button`
      );
      const isFirstAndOnlyRow = true;
      this.toggleRemoveButton(removeButton, isFirstAndOnlyRow);
    }

    if (deleteData) {
      this.saveData(e, rowId, deleteData);
    }
  };

  initDropdown = (clone) => {
    const albumElement = clone.querySelector(".album");
    const albumDropdown = clone.querySelector(".albums-dropdown");

    const optionsContainer = clone.querySelector(
      ".dropdown.album .options-container"
    );
    const options = optionsContainer.querySelectorAll("li");

    albumDropdown.addEventListener("click", () => {
      const albumDropdowns = this.block.querySelectorAll(".album");
      albumDropdowns.forEach((dropdown) => {
        if (dropdown.classList.contains("active")) {
          dropdown.classList.remove("active");
        }
      });
      albumElement.classList.toggle("active");
    });

    options.forEach((option) => {
      option.addEventListener("click", () => {
        this.handleDropdownOptionClick(albumDropdown, option, albumElement);
      });
    });
  };

  handleDropdownOptionClick = (albumDropdown, option, albumElement) => {
    albumDropdown.textContent = option.textContent;
    albumElement.classList.remove("active");
    this.handleAlbumColor(albumDropdown, option.textContent);
    this.saveData();
  };

  handleBeadsStyleOptionClick = (e, beadsStyleInput, option, beadsDropdown) => {
    beadsStyleInput.value = option.textContent;
    beadsDropdown.classList.remove("active");
    this.saveData();
  };

  initQuantityButtons = (clone) => {
    const decreaseButton = clone.querySelector(".decrease-button");
    const increaseButton = clone.querySelector(".increase-button");
    decreaseButton.addEventListener("click", this.handleQuantity);
    increaseButton.addEventListener("click", this.handleQuantity);

    const quantityNode = clone.querySelector(".quantity-input");
    const currentQuantity = this.getQuantityAsNumber(quantityNode);
    this.toggleQuantityButtons(currentQuantity, clone);
    quantityNode.addEventListener("input", this.onQuantityInput);
    quantityNode.addEventListener("blur", this.saveData);
  };

  initPhraseInputField = (clone, cloneId, isAddButton) => {
    const inputField = clone.querySelector(".phrase-input");
    this.setLabelId("phrase-input", "phrase label", clone, cloneId);
    inputField.addEventListener("input", this.onPhraseInput);
    inputField.addEventListener("blur", this.saveData);
    if (isAddButton) {
      inputField.focus();
    }
  };

  initBeadsStyleInputField = (clone, cloneId) => {
    const beadsDropdown = clone.querySelector(".beads-style.dropdown");
    const inputField = clone.querySelector(".beads-style-input");
    this.setLabelId("beads-style-input", "beads-style label", clone, cloneId);
    inputField.addEventListener("input", (e) =>
      this.onBeadsStyleInput(e, inputField, beadsDropdown)
    );
    // inputField.addEventListener("blur", (e) =>
    //   this.onBeadsStyleBlur(e, beadsDropdown)
    // );
  };

  setLabelId = (inputSelector, labelSelector, row, rowId) => {
    const inputField = row.querySelector(`.${inputSelector}`);
    const inputLabel = row.querySelector(`.${labelSelector}`);
    const inputId = `${inputSelector}-${rowId}`;
    inputField.setAttribute("id", inputId);
    inputLabel.setAttribute("for", inputId);
  };

  initArchiveButton = (clone) => {
    const archiveButton = clone.querySelector(".archive-button");
    archiveButton.addEventListener("mouseenter", this.showTooltip);
    archiveButton.addEventListener("mouseleave", this.hideTooltip);
    archiveButton.addEventListener("click", (e) => {
      const isCompleted = clone.getAttribute("data-completed");

      if (isCompleted === "true") {
        clone.setAttribute("data-completed", false);
        this.saveData(e, clone.getAttribute("data-id"));
      } else {
        clone.setAttribute("data-completed", true);
        this.saveData(e, clone.getAttribute("data-id"));

        if (!this.showCompleted) {
          e.target.closest("div.row").classList.add("no-display");
        }
      }
    });
  };

  initRemoveButton = (clone, isFirstAndOnlyRow = false) => {
    const removeButton = clone.querySelector(".remove-row-button");
    if (!clone.getAttribute("data-remove-button-initialized")) {
      const deleteData = true;
      removeButton.addEventListener("mouseenter", this.showTooltip);
      removeButton.addEventListener("mouseleave", this.hideTooltip);
      removeButton.addEventListener("click", (e) => {
        this.onRemoveButtonClick(e, deleteData);
      });

      // Set a flag to indicate that the event listener has been initialized.
      clone.setAttribute("data-remove-button-initialized", true);
    }
    this.toggleRemoveButton(removeButton, isFirstAndOnlyRow);
  };

  initCompletedCheckbox = async () => {
    this.showCompleted = Storage.get(ETB_SETTINGS_KEY)?.showCompleted;
    const checkbox = this.completedCheckbox.querySelector(".checkbox");
    checkbox.checked = this.showCompleted;
    const isCheckboxInit = true;
    this.toggleArchive(null, isCheckboxInit);
  };

  initSorter = async () => {
    this.sortBy = this.sorterSettings?.sortBy ?? "album";
    this.sortOrder = this.sorterSettings?.sortOrder ?? "ascending";

    this.sortBySelected.innerText = this.sortBy;
    this.sortOrderSelected.innerText = this.sortOrder;

    const sortByOptions = this.sortByElement.querySelectorAll("li");
    const sortOrderOptions = this.sortOrderElement.querySelectorAll("li");

    sortByOptions.forEach((option) =>
      option.addEventListener("click", (e) => this.onSorterClick(e, true))
    );
    sortOrderOptions.forEach((option) =>
      option.addEventListener("click", (e) => this.onSorterClick(e, false))
    );
  };

  onSorterClick = async (e, isSortBy = true) => {
    this.sorterLoading = true;
    this.sorterSpinner.classList.add("show");

    // setting a timeout to properly toggle the spinner class before the process hangs
    // another shit implementation :D
    setTimeout(async () => {
      const selectedValue = e.target.getAttribute("value").toLowerCase();

      if (isSortBy) {
        this.sortBy = selectedValue;
        this.sortBySelected.innerText = selectedValue;
        const sortOrder = this.sortOrderSelected.innerText;
        await this.initTable(selectedValue, sortOrder);
        this.sorterLoading = false;
        this.sorterSpinner.classList.remove("show");
      } else {
        this.sortOrder = selectedValue;
        this.sortOrderSelected.innerText = selectedValue;
        const sortBy = this.sortBySelected.innerText;
        await this.initTable(sortBy, selectedValue);
        this.sorterLoading = false;
        this.sorterSpinner.classList.remove("show");
      }

      const currentSettings = await Storage.get(ETB_SETTINGS_KEY);

      await Storage.set(ETB_SETTINGS_KEY, {
        ...currentSettings,
        sorter: { sortBy: this.sortBy, sortOrder: this.sortOrder },
      });
    }, 200);
  };

  onRemoveButtonClick = (e, deleteData) => {
    // getting a new id since it's probably changed when other rows where removed
    const currentRowNewId = e.target
      .closest("div .row")
      .getAttribute("data-id");
    this.removeRow(e, currentRowNewId, deleteData);
  };

  showTooltip = (e) => {
    e.target.querySelector(".tooltip").classList.add("visible");
  };

  hideTooltip = (e) => {
    e.target.querySelector(".tooltip").classList.remove("visible");
  };

  toggleRemoveButton = (button, hide = false) => {
    if (hide) {
      button.closest("div.row").classList.add("row--remove-disabled");
      button.classList.add("hidden");
      button.setAttribute("tabindex", "-1");
      button.setAttribute("aria-hidden", "true");
    } else {
      button.closest("div.row").classList.remove("row--remove-disabled");
      button.classList.remove("hidden");
      button.setAttribute("tabindex", "-1");
      button.setAttribute("aria-hidden", "true");
    }
  };

  fillPhraseInputField = (clone, data) => {
    const { phrase } = data;
    const inputField = clone.querySelector(".phrase-input");
    inputField.value = phrase;
  };

  fillBeadsStyleInputField = (clone, data) => {
    const { beadsStyle } = data;
    const inputField = clone.querySelector(".beads-style-input");
    inputField.value = beadsStyle === "any style" ? "" : beadsStyle;
  };

  fillDropdown = (clone, data) => {
    const { album } = data;

    const albumDropdown = clone.querySelector(".albums-dropdown");

    albumDropdown.textContent = album;
    this.handleAlbumColor(albumDropdown, album);
  };

  fillQuantityButtons = (clone, data) => {
    const { quantity } = data;

    const quantityNode = clone.querySelector(".quantity-input");
    quantityNode.value = quantity;
    this.toggleQuantityButtons(quantity, clone);
  };

  saveOnInput = () => {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.saveData();
    }, this.typingDelay);
  };

  onPhraseInput = (e) => {
    this.saveOnInput();
  };

  onBeadsStyleInput = async (e, inputField, beadsDropdown) => {
    const inputValue = e.target.value;

    // const invalidCharsRegex = /[^a-zA-Z0-9&\s]/g;

    // if (invalidCharsRegex.test(inputValue)) {
    //   // inputField.value = inputValue.replace(invalidCharsRegex, "");
    // }

    // retrieve updated data
    const savedData = await Storage.get(ETB_DATA_KEY);
    const dataByBeadsStyle = divideArrayByProperty(savedData, "beadsStyle");
    const keys = Object.keys(dataByBeadsStyle);

    // remove any style and total
    const filteredKeys = keys.filter(
      (key) => key !== "any style" && key !== "total"
    );

    // give data to fuse
    this.fuse.setCollection(filteredKeys);
    const results = this.fuse.search(e.target.value);
    const matchingStrings = results.map((result) => result.item);

    // clear previously built dropdowns
    const optionsContainer = beadsDropdown.querySelector(".options-container");
    while (optionsContainer.childNodes.length > 0) {
      optionsContainer.removeChild(optionsContainer.lastChild);
    }

    // fill with options
    matchingStrings.forEach((match) => {
      const newOption = document.createElement("li");
      newOption.value = match;
      newOption.classList.add("dropdown-option");
      newOption.classList.add("style-option");
      newOption.classList.add("text-body");
      newOption.role = "option";
      newOption.ariaSelected = "false";
      newOption.textContent = match;
      newOption.addEventListener("click", (e) => {
        this.handleBeadsStyleOptionClick(
          e,
          inputField,
          newOption,
          beadsDropdown
        );
      });
      optionsContainer.appendChild(newOption);
    });

    // update dropdown instance (this code is a mess)
    this.dropdownInstance = getInstance(beadsDropdown);
    this.dropdownInstance.updateOptionsList();

    const options = beadsDropdown.querySelectorAll("li");

    if (!beadsDropdown.classList.contains("active") && !!options.length) {
      beadsDropdown.classList.add("active");
    } else if (!options.length) {
      beadsDropdown.classList.remove("active");
    }

    // this.handleAddStyleButton(inputValue, beadsDropdown);

    this.saveOnInput();
  };

  // onBeadsStyleBlur = async (e, beadsDropdown) => {
  //   // wait to see if it blurs because an option is selected
  //   setTimeout(() => {
  //     if (beadsDropdown.classList.contains("active")) {
  //       beadsDropdown.classList.remove("active");
  //     }
  //   }, 200);

  //   await this.saveData();
  // };

  updateActiveStyles = async (beadsDropdown) => {
    // save styles options
    const currentStyles = this.getArrayofStyles(beadsDropdown);
    this.resultsInstance.saveStyles(currentStyles);

    //  refetch saved styles
    this.savedStyles = await Storage.get(ETB_SETTINGS_KEY).styles;

    // reset all styles dropdowns
    const containers = this.rowsContainer.querySelectorAll(
      ".beads-style .options-container"
    );
    containers.forEach((container) => {
      while (container.childNodes.length > 0) {
        container.removeChild(container.lastChild);
      }

      const inputField = container.closest("input");

      if (!!this.savedStyles.length) {
        this.savedStyles.forEach((style) => {
          if (style !== "any style") {
            const newOption = document.createElement("li");
            newOption.value = style;
            newOption.classList.add("dropdown-option");
            newOption.classList.add("text-body");
            newOption.role = "option";
            newOption.ariaSelected = "false";
            newOption.textContent = style;
            newOption.addEventListener("click", (e) => {
              this.handleBeadsStyleOptionClick(
                e,
                inputField,
                newOption,
                beadsDropdown
              );
            });
            container.appendChild(newOption);
          }
        });
      }
    });
  };

  getArrayofStyles = (beadsDropdown) => {
    const activeStyles = [];

    const options = beadsDropdown.querySelectorAll(
      "li .beads-style-option-label"
    );
    options.forEach((option) => {
      activeStyles.push(option.innerText);
    });

    return activeStyles;
  };

  addAlbumClassname = (node, album, isOption = false) => {
    const className = album.toLowerCase().trim().replace(/ /g, "-");
    const secondaryClassName = `album-${className}-secondary`;
    const primaryClassName = `album-${className}`;
    node.classList.add(isOption ? secondaryClassName : primaryClassName);
  };

  handleAlbumColor = (node, album) => {
    const select = node;

    const classes = select.classList;

    for (var i = 0; i < classes.length; i++) {
      var currentClass = classes[i];
      if (currentClass.startsWith("album-")) {
        select.classList.remove(currentClass);
      }
    }

    this.addAlbumClassname(select, album);
  };

  onQuantityInput = (e) => {
    if (e.target.value > 100) {
      e.target.value = 100;
    }

    if (e.target.value == 0 && e.target.value !== "") {
      e.target.value = 1;
    }
  };

  toggleQuantityButtons = (currentQuantity, element) => {
    const decreaseButton = element.querySelector(".decrease-button");
    const increaseButton = element.querySelector(".increase-button");
    if (currentQuantity === 1) {
      decreaseButton.disabled = true;
    } else {
      decreaseButton.disabled = false;
    }

    if (currentQuantity === 100) {
      increaseButton.disabled = true;
    } else {
      increaseButton.disabled = false;
    }
  };

  getQuantityAsNumber = (element, isChild = false) => {
    const quantityNode = isChild
      ? element.parentNode.querySelector(".quantity-input")
      : element;
    return parseInt(quantityNode.value);
  };

  handleQuantity = (e) => {
    const button = e.target;
    const isDecreaseButton = button.classList.contains("decrease-button");
    const quantityNode = button.parentNode.querySelector(".quantity-input");
    const currentAmount = parseInt(quantityNode.value);

    let newAmount;

    if (isDecreaseButton) {
      // decrease quantity
      newAmount = currentAmount - 1;
    } else {
      // increase quantity
      newAmount = currentAmount + 1;
    }

    quantityNode.value = newAmount;

    this.toggleQuantityButtons(newAmount, button.parentNode);
    this.saveData();
  };

  getCharactersCount = () => {
    const combinedString = this.phrases
      .toString()
      .toLowerCase()
      .replace(/,/g, "")
      .replace(/ /g, "");

    const result = [...combinedString].reduce((acc, chr) => {
      acc[chr] = (acc[chr] || 0) + 1;
      return acc;
    }, {});

    return result;
  };

  populateAlbumOptions = (dataSet) => {
    const optionsContainer = this.rowTemplate.content.querySelector(
      `.dropdown.album .options-container`
    );
    dataSet.forEach((data) => {
      const newOption = document.createElement("li");
      newOption.value = data;
      newOption.classList.add("dropdown-option");
      newOption.classList.add("album-option");
      newOption.classList.add("text-body");
      newOption.role = "option";
      newOption.ariaSelected = "false";
      newOption.textContent = data;
      const isOption = true;
      this.addAlbumClassname(newOption, data, isOption);
      optionsContainer.appendChild(newOption);
    });
  };

  populateStylesOptions = (dataSet) => {
    const optionsContainer = this.rowTemplate.content.querySelector(
      `.dropdown.beads-style .options-container`
    );

    while (optionsContainer.childNodes.length > 0) {
      console.log("sv3");
      optionsContainer.removeChild(optionsContainer.lastChild);
    }

    dataSet.forEach((data) => {
      // exclude default style from dropdown
      if (data !== "any style") {
        const newOption = document.createElement("li");
        newOption.value = data;
        newOption.classList.add("dropdown-option");
        newOption.classList.add("text-body");
        newOption.role = "option";
        newOption.ariaSelected = "false";
        newOption.textContent = data;
        optionsContainer.appendChild(newOption);
      }
    });
  };

  saveOptionRemoval = async (valueToRemove) => {
    const newOptions = this.savedStyles.filter(
      (item) => item !== valueToRemove.toLowerCase()
    );

    const currentSettings = await Storage.get(ETB_SETTINGS_KEY);

    await Storage.set(ETB_SETTINGS_KEY, { ...currentSettings, newOptions });
  };

  saveData = async () => {
    const rows = this.block.querySelectorAll(".row");

    let data = [];

    rows.forEach((row) => {
      const phrase = row.querySelector(".phrase-input").value.toLowerCase();
      // if (!phrase || phrase === "") {
      //   return;
      // }
      let beadsStyle = row
        .querySelector(".beads-style-input")
        .value.toLowerCase();
      if (beadsStyle === "") {
        beadsStyle = "any style";
      }
      const album = row
        .querySelector(".albums-dropdown")
        .innerText.toLowerCase();
      const quantity = row.querySelector(".quantity-input").value;

      const currentRowId = row.getAttribute("data-id");
      const isCompleted = row.getAttribute("data-completed");

      let completed = false;

      if (isCompleted === "true") {
        completed = true;
      }

      data.push({ phrase, beadsStyle, album, quantity, completed });
    });

    // save to storage
    this.resultsInstance?.saveCharactersCount(data);
    await Storage.set(ETB_DATA_KEY, data);

    if (process.env.ENVIRONMENT === "development") {
      console.log("Data saved: ", data);
    }

    this.statsInstance?.initStats();
    this.getTotalBraceletsToMake(data);
  };

  toggleArchive = async (e, isCheckboxInit = false) => {
    const checkbox = this.block.querySelector(".checkbox");

    while (this.rowsContainer.childNodes.length > 1) {
      this.rowsContainer.removeChild(this.rowsContainer.lastChild);
    }

    const currentSettings = await Storage.get(ETB_SETTINGS_KEY);

    if (checkbox.checked) {
      this.showCompleted = true;
      Storage.set(ETB_SETTINGS_KEY, {
        ...currentSettings,
        showCompleted: true,
      });
    } else {
      this.showCompleted = false;
      Storage.set(ETB_SETTINGS_KEY, {
        ...currentSettings,
        showCompleted: false,
      });
    }

    if (!isCheckboxInit) {
      this.initTable();
    }
  };

  onArchiveKeydown = (e) => {
    if (e.key === " " || e.key === "Spacebar") {
      e.preventDefault();
      const checkbox = this.block.querySelector(".checkbox");
      checkbox.checked = !checkbox.checked;
      this.toggleArchive(e);
    } else if (e.key === "Enter") {
      e.preventDefault();
      const checkbox = this.block.querySelector(".checkbox");
      checkbox.click();
    }
  };

  onDocumentClick = (e) => {
    if (
      !e.target.classList.contains("albums-dropdown") &&
      !e.target.matches("li.album-option")
    ) {
      const albumElements = this.block.querySelectorAll(".album");
      albumElements.forEach((el) => el.classList.remove("active"));
    }

    if (
      !e.target.classList.contains("dropdown-option") &&
      !e.target.matches("li.style-option")
    ) {
      const styleElements = this.block.querySelectorAll(".beads-style");
      styleElements.forEach((el) => el.classList.remove("active"));
    }

    if (
      !e.target.classList.contains("sort-by-selected") &&
      !e.target.classList.contains("sort-by-option")
    ) {
      this.sortByElement.classList.remove("active");
    }

    if (
      !e.target.classList.contains("sort-order-selected") &&
      !e.target.classList.contains("sort-order-option")
    ) {
      this.sortOrderElement.classList.remove("active");
    }
  };

  onReady = async () => {
    this.mounted = true;

    const { albums } = tsData;

    await this.populateAlbumOptions(albums);

    this.sorterSettings = await Storage.get(ETB_SETTINGS_KEY)?.sorter;

    this.addRowButton.addEventListener("click", this.onAddClick);
    this.completedCheckbox.addEventListener("change", this.toggleArchive);
    this.completedCheckbox.addEventListener("keydown", this.onArchiveKeydown);

    document.addEventListener("click", this.onDocumentClick);
  };

  onComplete = async () => {
    this.completed = true;
    this.resultsInstance = getInstance(document.querySelector(".etb-results"));
    this.statsInstance = getInstance(document.querySelector(".etb-stats"));
    this.initTable();
    this.initCompletedCheckbox();
    this.initSorter();
  };

  onResize() {}

  onDestroy() {}
}
