export const divideArrayByProperty = (arr, propertyName) => {
  const dividedObjects = {};

  arr.forEach((item) => {
    const propertyValue = item[propertyName];

    if (!dividedObjects[propertyValue]) {
      dividedObjects[propertyValue] = [];
    }

    dividedObjects[propertyValue].push(item);
  });

  return dividedObjects;
};

export const reorderObject = (originalObject, keyToMove, targetPosition) => {
  // convert the original object into a Map for ordered properties
  const orderedMap = new Map(Object.entries(originalObject));

  // delete desired key from the current position
  orderedMap.delete(keyToMove);

  // create a new Map with the key at the specified position
  const reorderedMap = new Map();
  let currentIndex = 0;

  orderedMap.forEach((value, key) => {
    if (currentIndex === targetPosition) {
      reorderedMap.set(keyToMove, originalObject[keyToMove]);
    }
    reorderedMap.set(key, value);
    currentIndex++;
  });

  // if the target position is at the end, add key to the end
  if (currentIndex <= targetPosition) {
    reorderedMap.set(keyToMove, originalObject[keyToMove]);
  }

  // convert the Map back to an object
  return Object.fromEntries(reorderedMap);
};
