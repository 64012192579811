export default {
  albums: [
    "Taylor Swift",
    "Fearless",
    "Speak Now",
    "Red",
    "1989",
    "Reputation",
    "Lover",
    "Folklore",
    "Evermore",
    "Midnights",
    "TTPD",
  ],

  customAlbumsOrder: {
    "taylor swift": 1,
    fearless: 2,
    "speak now": 3,
    red: 4,
    1989: 5,
    reputation: 6,
    lover: 7,
    folklore: 8,
    evermore: 9,
    midnights: 10,
    ttpd: 11,
  },
};
