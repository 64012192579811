import "./style.css";
import layout from "./layout.html";
import Storage from "@utils/Storage";
import { ETB_DATA_KEY } from "../../utils/constants";

export default class Stats {
  static selector = ".etb-stats";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.statTemplate = this.block.querySelector(".stat-template");
    this.statsContainer = this.block.querySelector(".stats-container");
  }

  showStats = () => {
    this.block.classList.remove("no-display");
  };

  hideStats = () => {
    this.block.classList.add("no-display");
  };

  resetStats = () => {
    const statsNodes = this.block.querySelectorAll(".stat-count");
    statsNodes.forEach((node) => (node.innerText = 0));
  };

  updateUI = () => {
    if (!!Object.keys(this.groupedData).length) {
      this.showStats();
      this.resetStats();

      // don't show empty rows - rows with no phrase associated with it
      this.groupedData = Object.keys(this.groupedData).reduce((acc, key) => {
        const filteredArray = this.groupedData[key].filter(
          (item) => item.phrase !== ""
        );
        if (filteredArray.length > 0) {
          acc[key] = filteredArray;
        }
        return acc;
      }, {});

      Object.keys(this.groupedData).forEach((item) => {
        const amount = this.groupedData[item].reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.quantity),
          0
        );

        const node = this.statsContainer.querySelector(
          `.stat-${item.toLowerCase().trim().replace(/ /g, "-")}`
        );
        const statCount = node.querySelector(".stat-count");
        statCount.innerText = amount;
      });
      window.App.theHeader.showHeaderButtons(window.App.theHeader.statsButton);
    } else {
      this.hideStats();
      window.App.theHeader.hideHeaderButtons(window.App.theHeader.statsButton);
    }
  };

  groupData = (data) => {
    return new Promise((resolve, reject) => {
      // initialize an empty object to store the grouped data
      const groupedData = {};
      // iterate through the array and group objects by the "album" property
      data.forEach((item) => {
        const album = item.album;
        // check if the album name is already a key in the groupedData object
        if (!groupedData[album]) {
          // if not, create a new array for that album
          groupedData[album] = [];
        }
        // push the current object into the array for the corresponding album
        groupedData[album].push(item);
      });

      resolve(groupedData);
    });
  };

  initStats = async () => {
    this.data = await Storage.get(ETB_DATA_KEY);
    if (this.data) {
      this.groupedData = await this.groupData(this.data);
      this.updateUI();
    }
  };

  onReady = () => {
    this.mounted = true;
    this.initStats();
  };

  onResize() {}

  onDestroy() {}
}
