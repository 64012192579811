import Storage from "@utils/Storage";
import {
  ETB_DATA_FILENAME,
  ETB_DATA_KEY,
  ETB_RESULTS_KEY,
  ETB_SETTINGS_KEY,
} from "./constants";

export const restoreBackup = (event) => {
  return new Promise((resolve, reject) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = function (e) {
        try {
          // Parse the JSON data from the uploaded file
          const jsonData = JSON.parse(e.target.result);

          // Save each piece of data to the corresponding local storage key
          for (const key in jsonData) {
            if (jsonData.hasOwnProperty(key)) {
              Storage.set(key, jsonData[key]);
            }
          }

          resolve(true);
        } catch (error) {
          // Handle any parsing errors
          console.error("Error parsing JSON data:", error);
          reject(error);
        }
      };
      reader.readAsText(selectedFile);
    } else {
      reject(error);
    }
  });
};

export const exportBackup = async () => {
  return new Promise((resolve, reject) => {
    try {
      const braceletsData = JSON.stringify(Storage.get(ETB_DATA_KEY));
      const resultsData = JSON.stringify(Storage.get(ETB_RESULTS_KEY));
      const settingsData = JSON.stringify(Storage.get(ETB_SETTINGS_KEY));

      const mergedData = {
        [ETB_DATA_KEY]: braceletsData,
        [ETB_RESULTS_KEY]: resultsData,
        [ETB_SETTINGS_KEY]: settingsData,
      };

      const mergedDataString = JSON.stringify(mergedData);
      const blob = new Blob([mergedDataString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = ETB_DATA_FILENAME;
      a.click();
      URL.revokeObjectURL(url);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
