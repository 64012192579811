export default class Storage {
  static get = (key) => {
    const string = localStorage.getItem(key);
    let value;

    try {
      const json = JSON.parse(string);
      value = json;
    } catch (error) {
      console.log("Storage set error:", error);
      value = string;
    }

    return value;
  };

  static set = (key, value) => {
    const val = typeof value === "string" ? value : JSON.stringify(value);
    return localStorage.setItem(key, val);
  };

  static remove = (key) => {
    return localStorage.removeItem(key);
  };

  static clear = () => {
    return localStorage.clear();
  };
}
