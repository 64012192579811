import "./style.css";
import layout from "./layout.html";

export default class ScrollToTop {
  static selector = ".etb-scroll-to-top";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.button = this.block.querySelector("button");
  }
  onScroll = () => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollTop > 250) {
      this.block.classList.add("show");
    } else {
      this.block.classList.remove("show");
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  onReady() {
    this.button.addEventListener("click", this.scrollToTop);
  }

  onResize() {}

  onDestroy() {}
}
