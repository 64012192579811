import "./style.css";
import layout from "./layout.html";
export default class Footer {
  static selector = "footer";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;

    this.yearElement = this.block.querySelector(".footer-year span");
  }

  setupDate = () => {
    const startYear = 2023;
    const currentYear = new Date().getFullYear();

    const yearRange = `${startYear}-${currentYear}`;

    this.yearElement.textContent = yearRange;
  };

  onReady() {
    this.mounted = true;
    this.setupDate();
  }
}
