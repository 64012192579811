import "./style.css";
import layout from "./layout.html";
import { getInstance } from "../../index";

export default class Header {
  static selector = ".header";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.isDetached = this.block.classList.contains("header--detached");
    this.isSlidedIn = this.block.classList.contains("header--slided-in");
    this.scrollTop = null;
    this.scrollDownTimer = null;
    this.scrollDownVisible = false;
    this.detachY = this.block.offsetHeight;

    this.resultsButton = this.block.querySelector(".results-button");
    this.statsButton = this.block.querySelector(".stats-button");
    this.backupButton = this.block.querySelector(".backup-button");

    this.resultsSection = document.querySelector(".etb-results");
    this.statsSection = document.querySelector(".etb-stats");
  }

  hideHeaderButtons = (button) => {
    button.classList.add("no-display");
    button.classList.remove("underlineable");
  };

  showHeaderButtons = (button) => {
    button.classList.remove("no-display");
    button.classList.add("underlineable");
  };

  detach() {
    this.isDetached = true;
    this.block.classList.add("header--detached");
  }
  attach() {
    this.isDetached = false;
    this.block.classList.remove("header--detached");
  }
  slideIn() {
    this.isSlidedIn = true;
    this.block.classList.add("header--slided-in");
  }
  slideOut() {
    this.isSlidedIn = false;
    this.block.classList.remove("header--slided-in");
  }

  scrollTargetIntoView = (target) => {
    target.scrollIntoView({ behavior: "smooth" });
  };

  onResultsClick = () => {
    this.scrollTargetIntoView(this.resultsSection);
  };

  onStatsClick = () => {
    this.scrollTargetIntoView(this.statsSection);
  };

  onBackupClick = () => {
    this.backupModalInstance?.openModal();
  };

  // Lifecycle methods
  onReady = () => {
    this.mounted = true;
    this.resultsButton.addEventListener("click", this.onResultsClick);
    this.statsButton.addEventListener("click", this.onStatsClick);
    this.backupButton.addEventListener("click", this.onBackupClick);
  };

  onResize() {
    this.detachY = this.block.offsetHeight;
  }

  onScroll() {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const isScrollingUp = this.scrollTop
      ? scrollTop - this.scrollTop < 0
      : true;
    this.scrollTop = scrollTop;

    if (!this.isDetached && this.scrollTop > this.detachY) {
      // detach if is not detached and header is offscreen top
      this.detach();
    } else if (this.isDetached && this.scrollTop === 0) {
      // attach if is detached and header is completely visible on top
      this.attach();
      this.slideOut();
    }

    if (this.isSlidedIn && !isScrollingUp) {
      // slide out (hide) if is slided in (visible) and user is scrolling down
      this.slideOut();
    } else if (
      !this.isSlidedIn &&
      this.isDetached &&
      isScrollingUp &&
      this.scrollTop > 0
    ) {
      // slide in (show) if is slided out (hidden), detached, user is scrolling
      // up and header is not completely in visible (when attached)
      this.slideIn();
    }
  }

  onComplete = () => {
    this.completed = true;
    this.backupModalInstance = getInstance(
      document.querySelector(".etb-backup-modal")
    );
  };

  onDestroy() {
    this.resultsButton.removeEventListener("click", this.onResultsClick);
    this.statsButton.removeEventListener("click", this.onStatsClick);
    this.backupButton.removeEventListener("click", this.onBackupClick);
  }
}
